import './Footer.scss'

function Footer() {
    return(
    <div className='footer'>
        <p className='footer__text'>Made with React, by <a href='https://www.nickalbu.com' className='footer__link'>www.nickalbu.com</a></p>
    </div>
    )
}

export default Footer;